html,
body {
  min-height: 100%;
}

#__next {
  height: 100%;
}

code {
  font-family: 'Inter', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  padding: 0.1rem 0.4rem;
  font-size: 90%;
  /* color: #d400ff; */
  /* border-radius: 0.1335rem; */
}
code:not([class*='language-']):before,
code:not([class*='language-']):after {
  content: '`';
}
code[class*='language-'] {
  padding: 0;
}

/* .nav-header {
  background-color: #d400ff;
} */

/* .layout-wrapper {
  background-color: #d400ff;
} */

/* .layout-navbar {
  background-color: #d400ff;
} */
