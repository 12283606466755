@font-face {
  font-family: 'Geograph';
  src: url('./fonts/Geograph-Medium.woff2') format('woff2'),
    url('./fonts/Geograph-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Geograph';
  src: url('./fonts/Geograph-MediumItalic.woff2') format('woff2'),
    url('./fonts/Geograph-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Geograph';
  src: url('./fonts/Geograph-BlackItalic.woff2') format('woff2'),
    url('./fonts/Geograph-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Geograph';
  src: url('./fonts/Geograph-Black.woff2') format('woff2'),
    url('./fonts/Geograph-Black.woff') format('woff');
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'Geograph';
  src: url('./fonts/Geograph-ThinItalic.woff2') format('woff2'),
    url('./fonts/Geograph-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Geograph';
  src: url('./fonts/Geograph-Regular.woff2') format('woff2'),
    url('./fonts/Geograph-Regular.woff') format('woff');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geograph';
  src: url('./fonts/Geograph-LightItalic.woff2') format('woff2'),
    url('./fonts/Geograph-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Geograph Regular';
  src: url('./fonts/Geograph-RegularItalic.woff2') format('woff2'),
    url('./fonts/Geograph-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Geograph';
  src: url('./fonts/Geograph-Thin.woff2') format('woff2'),
    url('./fonts/Geograph-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geograph';
  src: url('./fonts/Geograph-Light.woff2') format('woff2'),
    url('./fonts/Geograph-Light.woff') format('woff');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Geograph';
  src: url('./fonts/Geograph-BoldItalic.woff2') format('woff2'),
    url('./fonts/Geograph-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Geograph';
  src: url('./fonts/Geograph-Bold.woff2') format('woff2'),
    url('./fonts/Geograph-Bold.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
